<template>
  <BaseLayout>
    <Breadcrumbs />
    <section class="login">
      <BContainer>
        <BRow>
          <BCol offset-lg="1" offset-xl="2" lg="10" xl="8" class="position-relative">
            <span class="login-title text-white">{{ $t("login") }}</span>
            <div class="login-component">
              <div class="d-flex flex-column flex-md-row justify-content-between">
                <div class="info login--inner-component pb-25 pb-md-0">
                  <span class="title d-block">{{ $t("new_customers") }}</span>
                  <p class="mb-15 mb-md-45">
                    {{ $t("register_info") }}
                  </p>
                  <div class="text-end py-4">
                    <BButton
                      @click="$router.push({ name: 'create-account' })"
                      variant="info"
                      class="text-white py-3 px-15"
                      >{{ $t("create_account") }}</BButton
                    >
                  </div>
                </div>
                <div class="form login--inner-component pt-25 pt-md-0">
                  <span class="title d-block">{{ $t("registered_customers") }}</span>
                  <span class="form-description d-block mb-30">{{ $t("account_login") }}</span>
                  <BForm @submit.prevent="onLoginFormSubmit" novalidate>
                    <BFormGroup
                      class="mb-6"
                      id="email-group"
                      :label="$t('req_email')"
                      label-for="email"
                    >
                      <BFormInput
                        id="email"
                        v-model="form.email"
                        :state="loginValidation.emailState"
                        @blur="validateEmail"
                        type="email"
                        required=""
                        aria-describedby="email-feedback"
                      ></BFormInput>
                      <BFormInvalidFeedback id="email-feedback">
                        {{ loginValidation.emailError }}
                      </BFormInvalidFeedback>
                    </BFormGroup>
                    <BFormGroup
                      class="mb-6"
                      id="password-group"
                      :label="$t('req_password')"
                      label-for="password"
                    >
                      <BFormInput
                        id="password"
                        v-model="form.password"
                        type="password"
                        required=""
                        :state="loginValidation.passwordState"
                        @blur="validatePassword"
                        aria-describedby="password-feedback"
                      ></BFormInput>
                      <BFormInvalidFeedback id="password-feedback">
                        {{ loginValidation.passwordError }}
                      </BFormInvalidFeedback>
                    </BFormGroup>
                    <span class="form-info mb-15 d-block">{{ $t("required_fields") }}</span>
                    <div class="d-flex justify-content-between align-items-center">
                      <router-link
                        to="/forgot-password"
                        class="font-weight-normal link-underline"
                        >{{ $t("password_forgot") }}</router-link
                      >
                      <BButton
                        type="submit"
                        variant="info"
                        class="text-white ml-20 form-submit-btn"
                        >{{ $t("login") }}</BButton
                      >
                    </div>
                  </BForm>
                </div>
              </div>
            </div>
          </BCol>
        </BRow>
      </BContainer>
    </section>
  </BaseLayout>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import BaseLayout from "@/esf_thehague_fietskledingvoordeel/core/components/BaseLayout";
import Breadcrumbs from "@/base/core/components/Breadcrumbs";
import { useI18n } from "vue-i18n";
import { useHead } from "@unhead/vue";
import { useLogin } from "@storefront/core/composable/login";

const { login } = useLogin();
const { t } = useI18n();
const router = useRouter();
const store = useStore();

// Reactive form and validation objects
const form = ref({
  email: "",
  password: "",
});

const loginValidation = ref({
  emailState: null,
  emailError: "",
  passwordState: null,
  passwordError: "",
});

const emailReg =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/; // Sample regex for email validation

// Method to handle form submission
const onLoginFormSubmit = () => {
  let formValid = true;
  if (loginValidation.value.emailState !== true) {
    validateEmail();
    if (loginValidation.value.emailState !== true) {
      formValid = false;
    }
  }

  if (loginValidation.value.passwordState !== true) {
    validatePassword();
    if (loginValidation.value.passwordState !== true) {
      formValid = false;
    }
  }

  if (formValid) {
    login(form.value);
  }
};

// Email validation
const validateEmail = () => {
  if (form.value.email.length === 0) {
    loginValidation.value.emailState = false;
    loginValidation.value.emailError = t("email_is_required_field");
    return false;
  }
  if (!emailReg.test(form.value.email)) {
    loginValidation.value.emailState = false;
    loginValidation.value.emailError = t("email_is_invalid_field");
    return false;
  }
  loginValidation.value.emailState = true;
  return true;
};

// Password validation
const validatePassword = () => {
  if (form.value.password.length === 0) {
    loginValidation.value.passwordState = false;
    loginValidation.value.passwordError = t("password_is_required_field");
    return false;
  }
  loginValidation.value.passwordState = true;
  return true;
};

// Set up meta information using useHead
useHead({
  title: () => t("login"),
  meta: [
    { name: "title", content: () => t("login") },
    { name: "keywords", content: () => t("login") },
    { name: "description", content: () => t("login") },
  ],
});

const isLoggedIn = computed(() => store.getters["user/getIsLoggedIn"]);

watch(isLoggedIn, (newVal) => {
  if (newVal) {
    router.push({ name: "account" });
  }
});

onMounted(() => {
  const bcrumb = { current: t("login"), routes: [] };
  store.commit("breadcrumbs/set", bcrumb);
});
</script>
<style scoped>
@import "@/base/core/components/login/login.scss";
</style>
